import { ElMessageBox } from 'element-plus';
import { ref } from 'vue';
import { apiEgpmTaskAgain } from '@/request/egpmBrandApi';
import { useRoute, useRouter } from "vue-router";
import withLoading from '@/utils/loading';

export default ({ getTaskDetails }) => {

    const route = useRoute();
    const router = useRouter();
    const addAgainShow = ref(false);// 增量计算弹窗

    // 重新执行
    function handleAgain() {
        ElMessageBox.confirm('确定要重新执行吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(async () => {
            const { code } = await withLoading(apiEgpmTaskAgain)({ taskId: route.query.id });
            if (code !== '0') return;
            // ElMessage.success('执行成功');
            refreshTask();
        })
    }

    // 重新执行后刷新任务页面
    function refreshTask() {
        getTaskDetails();
        router.replace({
            path: '/egpm/brand/details/params',
            query: {
                reportId: route.query.reportId,
                id: route.query.id
            }
        })
    }

    return { addAgainShow, handleAgain, refreshTask }
}